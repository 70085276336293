<template lang="pug">
app-layout
  v-app.layout-full
    v-main
      .page-create-pro
        .sh-1
          v-toolbar.px-2(flat)
            v-spacer
            v-toolbar-title.t-h3-mobile.mb-0 Получение статуса PRO
            v-spacer
            ui-btn(icon plain @click="cancel")
              ui-icon(name="close")
        .px-4.mt-8
          template(v-if="receivedStatusPro")
            .status-pro-logo.mb-2
            .t-h1-mobile.mb-4 Вы получили статус PRO!
            .t-h4-mobile.mb-4 Теперь вам доступны новые функции
            ul.mb-11.pl-0
              li.mb-2.d-flex.align-center
                ui-icon.mr-3.c-primary(name="check2")
                .c-black80 Участие в аукционах
              li.mb-2.d-flex.align-center
                ui-icon.mr-3.c-primary(name="check2")
                .c-black80 Размещение актива на продажу
              li.d-flex.align-center
                ui-icon.mr-3.c-primary(name="check2")
                .c-black80 Доступ к закрытой части
            ui-btn(
              large
              secondary
              width="100%"
              @click="cancel"
            ) Закрыть

          template(v-else)
            .t-h4-mobile.mb-4 Паспортные данные
            v-text-field.mb-6(
              v-model="name"
              type="text"
              label="ФИО"
              hide-details="auto"
              key="name"
              :error-messages="errorMsg('name')"
            )
              template(v-if="name && !errors.name" #append)
                ui-icon.c-primary(name="check2")

            ui-date-picker.mb-3(
              v-model="date"
              label="Дата рождения"
              closeOnDateSelect
              key="date"
            )
            v-messages.error--text.mt-2(
              v-if="errorMsg('birth_date')"
              :value="[errorMsg('birth_date')]"
            )

            v-text-field.mb-6(
              v-model="passport.identification_number"
              type="text"
              label="Серия и номер паспорта"
              hide-details="auto"
              key="passport_identification_number"
              v-maska="maska.fields.passport.maska"
              :error-messages="errorMsg('identification_number')"
            )
              template(
                v-if="passport.identification_number && passport.identification_number.length == 12 && !errors.passport"
                #append
              )
                ui-icon.c-primary(name="check2")

            ui-date-picker.mb-3(
              v-model="passport.when"
              label="Дата выдачи"
              closeOnDateSelect
              key="passport_when"
              :error-messages="errorMsg('passport_when')"
            )
            v-messages.error--text.mt-2(
              v-if="errorMsg('passport_when')"
              :value="[errorMsg('passport_when')]"
            )

            v-text-field.mb-6(
              v-model="passport.who"
              type="text"
              label="Кем выдан"
              hide-details="auto"
              key="passport_who"
              :error-messages="errorMsg('passport_who')"
            )
              template(v-if="passport.who && !errors.passport" #append)
                ui-icon.c-primary(name="check2")

            v-text-field.mb-10(
              v-model="passport.address"
              type="text"
              label="Адрес регистрации"
              hide-details="auto"
              key="passport_address"
              :error-messages="errorMsg('passport_address')"
            )
              template(v-if="passport.address && !errors.passport" #append)
                ui-icon.c-primary(name="check2")

            .t-h4-mobile.mb-6 ИНН
            v-text-field.mb-6(
              v-model="inn"
              type="text"
              label="ИНН"
              hide-details="auto"
              key="inn"
              v-maska="maska.fields.inn.maska"
              :error-messages="errorMsg('inn')"
            )
              template(v-if="inn && inn.length == 12 && !errors.inn" #append)
                ui-icon.c-primary(name="check2")
            a.c-primary60(href="https://www.nalog.gov.ru/" rel="nofollow" target="_blank") Узнать ИНН на сайте nalog.ru
            .d-flex.align-start.mb-10.mt-6
              v-checkbox.ma-0.pa-0(
                v-model="individual"
                hide-details="auto"
                key="individual"
                :error-messages="errorMsg('individual')"
              )
              .t-caption-normal(:class="individual ? 'c-black' : 'c-text'")
                | Являюсь индивидуальным предпринимателем

            .t-h4-mobile.mb-4 СНИЛС
            v-text-field.mb-8(
              v-model="snils"
              type="text"
              label="СНИЛС"
              hide-details="auto"
              key="snils"
              v-maska="maska.fields.snils.maska"
              :error-messages="errorMsg('snils')"
            )
              template(v-if="snils && snils.length == 14 && !errors.snils" #append)
                ui-icon.c-primary(name="check2")

            ui-loader.my-6(preloader :loading="loading")
            ui-btn.mb-5(
              large
              primary
              width="100%"
              @click="create"
              :disabled="!disableBtn"
            ) Получить статус PRO
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';

  import { UiBtn, UiIcon, UiLoader, UiDatePicker } from '@/uikit';
  import { maska } from 'maska';
  import Constants from '@/uikit/util/constants';

  import { mapGetters, mapActions } from 'vuex';
  import { service as API } from '@/services/auth';
  import { handleError } from '@/libs/handle-error';
  import { formErrors } from '@/uikit/mixins/formErrors';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageCreatePro', 'blue');

  export default {
    name: 'page-create-pro',
    components: {
      AppLayout,
      UiBtn,
      UiIcon,
      UiLoader,
      UiDatePicker,
    },
    directives: {
      maska,
    },
    mixins: [formErrors],

    data: () => ({
      isActive: false,
      loading: false,
      name: undefined,
      date: undefined,
      passport: {
        identification_number: undefined,
        when: undefined,
        who: undefined,
        address: undefined,
      },
      inn: undefined,
      individual: false,
      snils: undefined,
      errors: [],
      maska: Constants,
      receivedStatusPro: false,
    }),

    computed: {
      disableBtn() {
        return (
          this.name &&
          this.date &&
          this.passport.identification_number?.length == 12 &&
          this.passport.when &&
          this.passport.who &&
          this.passport.address &&
          this.inn?.length == 12 &&
          this.snils?.length == 14 &&
          !this.errors.length
        );
      },

      ...mapGetters(['currentUser', 'userToken']),
    },

    created() {
      this.name = this.currentUser.name;
    },

    methods: {
      async create() {
        try {
          this.clearErrors();
          this.loading = true;

          const user_info = {
            name: this.name,
            birth_date: this.date,
            identification_number: this.passport.identification_number,
            passport_who: this.passport.who,
            passport_when: this.passport.when,
            passport_address: this.passport.address,
            individual: this.individual,
            inn: this.inn,
            snils: this.snils,
          };

          const ok = await API.createStatusPro(user_info);
          if (ok) {
            const user = await API.getUserInfo();
            this.updateUser(user);
            logger.log('Обновляем данные и статус пользователя', user);
            this.$nextTick(() => {
              this.receivedStatusPro = user.status === 'pro';
            });
          }
        } catch (error) {
          handleError(error, logger);

          if (error.data && error.data.data) {
            this.$notify.error(error.data.message);
            this.extractResponseErrors(
              error.data,
              'name',
              'birth_date',
              'identification_number',
              'passport_who',
              'passport_when',
              'passport_address',
              'individual',
              'inn',
              'snils'
            );
          }
        } finally {
          this.loading = false;
        }
      },

      cancel() {
        logger.log('close page & back');
        this.$router.push(this.$path.home());
      },

      ...mapActions('user', ['signInWithToken', 'updateUser']),
    },
  };
</script>

<style lang="scss">
  .page-create-pro {
    .ui-loader {
      display: flex;
    }
    .status-pro-logo {
      width: 96px;
      height: 96px;
      @include svg-bg-colored('pro', 'assets/96');
    }
    .v-text-field__slot label.v-label--active {
      color: #909497 !important;
    }
    .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
      background: none;
    }
  }
</style>
