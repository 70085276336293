export const formErrorsProps = {
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
};

export const formErrors = {
  data() {
    return {
      errors: {},
    };
  },

  computed: {
    hasAnyErrors: function () {
      let hasErrors = false;
      for (const field in this.errors) {
        if (Object.prototype.hasOwnProperty.call(this.errors, field)) {
          hasErrors = hasErrors || this.errors[field].length > 0;
        }
      }
      return hasErrors;
    },
  },

  methods: {
    extractResponseErrors(responseData, ...fields) {
      if (!responseData) return;

      fields.forEach((field) => {
        if (responseData.data[field]?.error) {
          this.addError(responseData.data[field].error, field);
        }
      });
    },

    setErrors(errors_arr) {
      if (!errors_arr) return;

      errors_arr.forEach((err) => {
        this.addError(err.error, err.field);
      });
    },

    errorMsg: function (field = 'default') {
      return this.hasError(field) ? this.errors[field] : '';
    },

    hasError(...fields) {
      fields = fields || ['default'];
      return fields.reduce((error, name) => {
        return error || Boolean(this.errors[name]?.length);
      }, false);
    },

    addError(msg, field = 'default') {
      this.$set(this.errors, field, msg);
    },

    flashError(msg, field = 'default', timeout = 4000) {
      this.addError(msg, field);
      if (timeout > 0) {
        setTimeout(() => {
          this.clearError(field);
        }, timeout);
      }
    },

    clearError(field = 'default') {
      if (this.hasError(field)) {
        this.$delete(this.errors, field);
      }
    },

    clearErrors() {
      this.errors = {};
    },
  },
};
